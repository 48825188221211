.main_header{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main_title{
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
}

.additional_part{
    display: flex;
    align-items: center;
}

.upload_file{
    display:flex;
    gap: 5px;
    align-items: center;
}
.search{
    display: flex;
    padding: 5px;
    gap: 5px;
    padding: 0 30px;
}


