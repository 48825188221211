.table_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pure-white);
    border-radius: 20px;
    margin: 0px 40px;
}

.popup_wrapper{
    display: flex;
    flex-direction: column;
    padding: 20px;
}
