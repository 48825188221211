
.container{
    padding: 0 20px 0 20px;
}

.data_pribadi_wrapper{
    /* background-color: var(--color-pure-white); */
    border-radius: 20px;
}


.additional_container {
    display: flex;
    width:100%;
} 

.document_wrapper{
    margin: 20px 0 10px 0; 
    flex-grow:8;
    background-color: var(--color-pure-white);
    border-radius: 20px;
    padding: 20px;
}

.status_wrapper{
    margin: 20px 0 0 10px ; 
    flex-grow:2;
    background-color: var(--color-pure-white);
    border-radius: 20px;
    padding: 20px;
}
