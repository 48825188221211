@import "../vars.module.css";

.input {
  border-radius: 8px;
  background: #fff;
  padding: 17px 31px;

  /* font */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  transition: border 0.5s, background 0.5s;
  outline: none;
}

.input:hover {
  /* background: #d2d2f6; */
  /* border: #d2d2f6 2px solid; */
}

.input:focus {
  /* background: #d2d2f6; */
  border: var(--color-primary) 2px solid;
}

.lite {
  padding: 5px 31px 5px 10px;
  border-radius: 5px;
}

.fileInput {
  background: #ffffff;
  border: 1px #00000;
  text-align: center;
  padding: 17px 0;
  color: #a9a9a9;
  font-family: Inter;
}

.fileInputLabel {
  display: inline-block;
  color: #a9a9a9;
  border: 1px solid #fff;
  text-align: center;
  padding: 17px 0;
  border-radius: 5px;
  background: #fff;
  font-family: Inter;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
}

.fileInputLabel:hover {
  border: 1px solid #a9a9a9;
}
