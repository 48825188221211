@import './vars.module.css';

.login{
    display: flex;
    flex-direction: row;
    height:100%;
    background-color: var(--color-white);
}

.bg_part{
    flex-grow: 5;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_part{
    flex-grow:1;
    z-index: 2;
    background-color: white;
    box-shadow: -4px 0px 20px var(--color-light-grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 25px;
    padding-left: 55px;
}

.login_form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login_title{
    color: #262626;
    font-family: Inter;
    font-size: 2rem;
    font-weight: 700;
    line-height: normal; 
    font-style: normal;
}

.rect{
    z-index: 1;
    position:fixed;
    border-radius: 80px;
    background-color: var(--color-primary);
}

.top_left_rect{
    left: -150px;
    top: -150px;
    width: 500px;
    height: 250px;
    opacity: 75%;
    border-radius: 50px;;
}

.top_right_rect{
    right: 450px;
    top: -500px;
    width: 500px;
    height: 700px;
    opacity: 35%;
}

.left_rect{
    top: 150px;
    left: -450px;
    width: 600px;
    height: 400px;
    opacity: 10%;
}

.bottom_rect{
    width:100vw;
    height: 300px;
    left: 250px;
    bottom: -100px;
}
