.hide_scroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.hide_scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.dashboard {
  display: flex;
  flex-direction: row;
}

.dashboard_panel {
  height: 100vh;
  overflow-y: scroll;
  background-color: var(--color-pure-white);
  /* box-shadow: 1px 1px 20px var(--color-light-grey); */
}

.dashboard_toggle {
  box-shadow: 10px 0 20px -10px var(--color-light-grey);
}

.dashboard_content {
  overflow-y: scroll;
  flex-grow: 18;
  flex-shrink: 0;
  height: 100vh;
  background-color: var(--color-white);
}

.dashboard_header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0 20px;
}

.dashboard_name {
  color: #1b1b1b;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dashboard_rank {
  color: #1b1b1b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logo {
  width: 50px;
}

.dashboard_loader {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
