:root {
    --color-primary: #2d61d6;
    --color-secondary: #2c3d8f;
    --color-light-secondary: #cddeff;
    --color-grey: #79818e;
    --color-light-grey: #abb1ce;
    --color-opaque-grey: #F3F6FE;
    --color-black: #171d2b;
    --color-white: #f5f7fc;
    --color-pure-white: #fff;
    --color-green: #29B72F;
}
