@import "../vars.module.css";
.header {
  padding: 40px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.lite {
  padding: 10px;
}

.left_part {
  display: flex;
}

.right_part {
  display: flex;
}

.title {
  font-size: 2rem;
  font-weight: bold;
}
.title_lite {
  font-size: 1.5rem;
  font-weight: bold;
}

.backButton {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.5s;
}

.backButton:hover {
  background-color: var(--color-light-grey);
}
