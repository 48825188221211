.title{
    font-weight: 800;
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.latest_kontrak{
    border-radius: 3px;
    padding: 5px ;
    font-weight: 800;
}

.active_kontrak{
    color: white;
    background-color: var(--color-green);
}

.expired_kontrak{
   background-color:  var(--color-light-grey);
   color: var(--color-black);
}

.button_wrapper{
    margin-top: 20px ;
}
