.nav {
  background: #15171c;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sidebar {
}

.item_wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 10px 20px;
}
