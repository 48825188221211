.container {
    width: 100%;
    border-bottom: 1px solid var(--color-light-grey);
    margin-top: 10px;
}
.title{
    display: flex;
    font-size: 1.2rem;
    font-weight: bold;
}

.table{
    width: 100%;
    border-radius: 10px;
    text-align: left;
    background-color: var(--color-pure-white);
}

.t_head{
    padding: 10px;
}

.t_row:nth-child(odd){
    background-color: var(--color-opaque-grey);
}

.t_cell{
    padding: 15px 10px;
}

th.t_cell{
    padding: 10px;
}
