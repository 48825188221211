.container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pure-white);
    border-radius: 20px;
    padding: 20px;
    margin: 0px 40px;
    margin-bottom: 20px;
}

.form_container{
    display: grid;
    text-align: left;
    grid-template-columns: 20% 80%;
    gap: 20px;
}
