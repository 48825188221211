.container{
    display:flex;
    width: 100%;
    justify-content: space-around;
}

.list_container{
    width: 500px;
    display: flex;
    flex-direction: column;
}

.pagination{
    display: flex;
    justify-content: center;
}

.header{
}

.listItem{
}

.search{
    margin: 0 20px;
}

.unselected{
    display: flex;
    text-align: left;
    margin: 10px 20px;
    justify-content: space-between;
    align-items: center;
}

