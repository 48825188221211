@import '../vars.module.css';
.container{
    background-color: #00000060;
    position: fixed;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content:center;
    align-items: center;
    z-index: 10;
}

.header{
    display: flex;
    justify-content: end;
}

.closeIcon {
    width: 30px;
    cursor: pointer;
}

.card{
    background-color: var(--color-white);
    min-width: 400px;
    min-height: 400px;
    border-radius: 20px;
    padding: 20px;
}
