.item{
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px;
    border-bottom: 1px var(--color-light-grey) solid;
    justify-content: start;
    gap: 10px;
}

.image{
    width: 40px;
    height: 40px;
    background-color: var(--color-grey);
    border-radius: 100px;
    margin: 5px;
}

.info{
    text-align: left;
}

.nama{
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: bold;
}

.iconButton{
    margin-left: auto;
    margin-right: 10px;
}
.icon{
    cursor: pointer;
}
.icon_grey{
    cursor:default;
}



