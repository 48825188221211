.pagination_wrapper {
  display: flex;
  width: 75%;
  justify-content: center;
  gap: 8px;
}

.pagination_item {
  cursor: pointer;
}
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table {
  width: 90%;
  border-radius: 10px;
  text-align: left;
  background-color: var(--color-pure-white);
}

.t_head {
  padding: 10px;
}

.t_row:nth-child(odd) {
  background-color: var(--color-opaque-grey);
}

.t_cell {
  padding: 15px 10px;
}

th.t_cell {
  padding: 10px;
}
