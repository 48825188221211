.container{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title{
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
}

.wrapper{
    margin: 20px;
}

.file_detail{
    word-wrap: break-word;
    max-width: 400px;
}
