@import "../vars.module.css";

.default {
  cursor: pointer;
  color: var(--color-white);
  font-family: Inter;
  font-style: normal;
  line-height: normal;
}

/* type */
.SOLIDFILL {
  border: none;
  text-align: center;
}

.CLICKABLE_TEXT {
  border: none;
  background: none;
  font-weight: bold;
}

/* size */
.large {
  width: 100%;
  border-radius: 11px;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 20px;
}

.small {
  width: 100%;
  border-radius: 5px;
  font-size: 1rem;
  padding: 5px 15px;
}

.minimum {
  border-radius: 5px;
  font-size: 1rem;
  padding: 5px 10px;
}

/* color */
.bg_info {
  background: var(--color-primary);
}

.bg_info:hover {
  filter: brightness(0.9); /* Darken color on hover */
}

.bg_accept {
  background: var(--color-green);
}

.bg_accept:hover {
  filter: brightness(0.9); /* Darken color on hover */
}

.text_info {
  color: var(--color-primary);
}

.text_info:hover {
  filter: brightness(0.9); /* Darken text color on hover */
}

.text_danger {
  color: red;
}

.text_danger:hover {
  filter: brightness(0.9); /* Darken text color on hover */
}

.text_accept {
  color: var(--color-green);
}

.text_accept:hover {
  filter: brightness(0.9); /* Darken text color on hover */
}
