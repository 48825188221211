.data_pribadi{
    background-color: var(--color-pure-white);
    padding: 20px;
    border-radius: 20px;
}
.main_title{
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 0;
}

.info_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pure-white);
    border-radius: 20px;
    margin: 0px 40px 10px;
}

.table{
    text-align: left;
}

.cell{
    padding: 4px 10px;
}

.form_wrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding: 20px;
}

.column_container{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    width: 100%;
    margin: 0 20px;
}

.uneditable_field{
    display: grid;
    grid-template-columns:  minmax(9em, 40%) min-content 1fr;
}

.value{
    text-align: left;
}

.uneditable_bahasa{
    display: flex;
    flex-direction: column;
}
.bahasa_list_item{
    border-radius: 10px;
    margin-top: 5px;
    padding: 10px 20px;
    background: var(--color-white);

}

.list_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    border-bottom: 1px solid grey;
}

.list_label{
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
}
