.container{
    display: flex;
    flex-direction: column;
    width: 100%
}

.no_slip{
    align-self: start;
    font-size: 2rem;
    font-weight: bold;
    padding: 10px 20px;
}

.infobar{
    display: flex;
    justify-content: space-between;
}

.info_group{
    display: flex;
    padding: 10px 20px;
}

.info_item{
    padding: 0 10px;
}

.info_title{
    text-align: left;
}

.info_content{
    font-size: 1.4rem;
    font-weight: bold;
    text-align: left;
}

.tanggal_penggajian{
    border-left: 2px solid var(--color-grey);
    border-right: 2px solid var(--color-grey);
}

.download_button{
    display: flex;
    padding: 20px;
    gap: 10px;
}

.hasil_table{
}
.sum{
}

.pegawaiCell{
    display: flex;
    align-items: center;
}
.image{
    width: 40px;
    height: 40px;
    background-color: var(--color-grey);
    border-radius: 100px;
    margin: 5px;
}
.name_wrapper{
    margin-left: 10px;
}
.name{
    font-weight: bold;
    font-size: 1.2rem;
}
