.input_div {
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  align-items: start;
  width: 100%;
  padding: 10px;
}

.title {
  font-size: 2rem;
}

.periode_input {
  display: flex;
  align-items: center;
  gap: 20px;
}
