.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-pure-white);
  border-radius: 20px;
  padding: 20px;
  margin: 0px 40px;
  margin-bottom: 20px;
  flex-direction: column;
}

.form_container {
  width: 60%;
  display: grid;
  text-align: left;
  grid-template-columns: 20% 50%;
  gap: 20px;
}
