.form_wrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding: 20px;
}

.column_container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 40%;
}

.grid_container{
    display: grid;
    grid-template-columns: max-content auto;
    gap: 10px;
    padding: 10px;
}

.label {
    display: flex;
    align-items: center;
    width:max-content;
}

.button_holder {
    width: 100%;
    display: flex;
    justify-content: center;
}

.wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pure-white);
    border-radius: 20px;
    margin: 0px 40px;
}
