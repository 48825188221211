.container{
    background: var(--color-pure-white);
    margin: 20px;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
}

.table_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-pure-white);
  border-radius: 20px;
  margin: 20px 40px;
}

.header_container {
  display: flex;
  gap: 20px;
}
