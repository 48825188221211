.container {
  display: flex;
  border: 1px var(--color-light-grey) solid;
  padding: 5px 8px;
  gap: 5px;
  border-radius: 10px;
  background-color: var(--color-pure-white);
  width: 100%;
}

.input {
  border: none;
  width: 100%;
}
