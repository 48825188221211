.table_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pure-white);
    border-radius: 20px;
    margin: 0px 40px;
}

.popup{
    display: flex;
    flex-direction: column;
}

.popUpChild{
    text-align: left;
    display: grid;
    width: 100%;
    grid-template-columns: 20% 80%;
    gap: 5px;
    margin-bottom: 10px;
}
