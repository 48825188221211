.container{
    background: var(--color-pure-white);
    margin: 20px;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
}

.formContainer{
    display: grid;
    width: 80%;
    grid-template-columns: 20% 10% 70%;
}
