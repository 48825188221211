/* .wrapper{ */
/*     max-width: 900px; */
/*     overflow-x: scroll; */
/* } */

.list_wrapper{
    border-radius: 8px;
    max-width: 60vw;
}

.item_wrapper{
    margin: 10px;
    background: var(--color-white);
    padding: 10px;
    border-radius: 10px;
}

.date_wrapper{
    display:grid;
    text-align: left;
    grid-template-columns: max-content max-content;
}


