@import "../vars.module.css";

a {
  color: var(--color-grey);
  text-decoration: none;
}

.selected_link {
  color: var(--color-primary);
  text-decoration: none;
}

.group {
  background-color: var(--color-pure-white);
  text-align: start;
  width: 100%;
}

.group_link {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.group_icon {
  /* margin:0 20px; */
}

.item_wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 30px;
}

.item {
  display: flex;
  margin: 5px;
  justify-content: space-between;
  width: 100%;
  transition: color 0.3s;
}

.item:hover {
  color: var(--color-primary);
}

.indicator {
  width: 7px;
  height: 1.75rem;
  position: relative;
  right: -7px;
}
