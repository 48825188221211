.card_wrapper {
  box-sizing: border-box;
  width: 900px;
  display: flex;
  overflow-x: scroll;
  flex-direction: column;
  padding: 20px;
}

.title {
  text-align: left;
  margin-bottom: 20px;
  font-weight: bold;
}

.list_wrapper {
}

.table {
  text-align: left;
  width: min-content;
  min-width: 80%;
}

.num_col {
  width: 30px;
}
