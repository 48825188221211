.container{
    background-color: #00000060;
    position: fixed;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content:center;
    align-items: center;
    z-index: 10;
}

.card{
    background-color: var(--color-white);
    border-radius: 20px;
    padding: 80px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.icon{
    width: 120px;
}

.message{
    font-size: 2rem;
    font-weight: bold;
}
