.step_list{
    display: flex;
    justify-content: center;
    gap: 100px;
}

.step_item{
    font-size: 1.6rem;
    font-weight: bold;
    color: grey;
}

.current_step_item {
    color: black;
}

.component_container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pure-white);
    border-radius: 10px;
    padding: 20px 0;
    margin: 20px 20px;
}

.confirm_icon{
    width: 100px;
}

.confirm_title{
    font-size: 2rem;
    height: bold;
    margin-top: 30px;
}

.confirm_button_group{
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
}
