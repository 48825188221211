.bahasa_list_wrapper {
  background: var(--color-white);
  border-radius: 8px;
}

.list_item {
  display: flex;
  gap: 15px;
}

.title {
  text-align: left;
  margin-bottom: 20px;
  font-weight: bold;
}
