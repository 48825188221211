@import "../../../vars.module.css";

.table_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-pure-white);
  border-radius: 20px;
  margin: 20px 40px;
}

.header_container {
  display: flex;
  gap: 20px;
}
