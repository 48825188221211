.form_template_wrapper{
    background-color: var(--color-pure-white);
    padding: 20px;
    margin: 20px;
    border-radius: 15px;

    display: grid;
    grid-template-columns: max-content auto;
    gap: 10px;
}

.label{
    text-align: left;
    display: flex;
    align-items: center;
}

.static_value{
    text-align: left;
}

.document_wrapper{
    background-color: var(--color-pure-white);
    padding: 20px;
    margin: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
