
.table_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pure-white);
    border-radius: 20px;
    margin: 0px 40px;
}

.status_header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.status{
    display: flex;
    gap: 20px;
    justify-content: center;
}

.cell {
    width: 20px;
    text-align: center;
}
