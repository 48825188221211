.header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.header_container {
  display: flex;
  gap: 20px;
}

.table_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-pure-white);
  border-radius: 20px;
  margin: 20px 40px;
}

.parent_div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.parent_div > *:not(:last-child, :first-child) {
  margin-bottom: 20px;
}
